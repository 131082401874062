import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from "@angular/router";
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private meta: Meta,
                private titleService: Title,
                private router: Router) {
    }

    updateTitle(title: string): SeoService {
        this.titleService.setTitle(title);
        this.updateMetaTag('og:title', title, 'property');
        this.updateMetaTag('twitter:title', title, 'name');
        return this;
    }

    updateDescription(description: string): SeoService {
        this.updateMetaTag('description', description, 'name');
        this.updateMetaTag('og:description', description, 'property');
        this.updateMetaTag('twitter:description', description, 'name');
        return this;
    }

    updateKeywords(keywords: string): SeoService {
        this.updateMetaTag('keywords', keywords, 'name');
        return this;
    }

    updateOgUrl(): SeoService {
        const url = `${environment.appUrl}${this.router.url}`;
        this.updateMetaTag('og:url', url, 'property');
        return this;
    }

    updateOgImage(imageUrl: string = `${environment.appUrl}/assets/socialmedia-background.webp`): SeoService {
        this.updateMetaTag('og:image', imageUrl, 'property');
        this.updateMetaTag('twitter:image', imageUrl, 'name');
        return this;
    }

    updateOgType(type: string = 'website'): SeoService {
        this.updateMetaTag('og:type', type, 'property');
        return this;
    }

    updateTwitterCard(cardType: string = 'summary_large_image'): SeoService {
        this.updateMetaTag('twitter:card', cardType, 'name');
        return this;
    }

    updateRobots(robotsContent: string): SeoService {
        this.updateMetaTag('robots', robotsContent, 'name');
        return this;
    }

    updateLanguage(lang: string = environment.defaultLanguage): SeoService {
        this.updateMetaTag('language', lang, 'name');
        return this;
    }

    updateAuthor(author: string): SeoService {
        this.updateMetaTag('author', author, 'name');
        return this;
    }

    private updateMetaTag(name: string, content: string, attributeType: 'name' | 'property'): void {
        const tag = this.meta.getTag(`${attributeType}="${name}"`);
        const metaTag = {[attributeType]: name, content: content};

        tag ? this.meta.updateTag(metaTag) : this.meta.addTag(metaTag);
    }

    removeMetaTag(name: string): SeoService {
        this.meta.removeTag(`name='${name}'`);
        return this;
    }
}
